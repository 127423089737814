import axios from 'axios'

const DEFAULT_ZOOM_SIZE = 14

export default {
  name: 'MapView',
  props: ['address', 'initialZoom'],
  created() {
    this.fetchMap()
  },
  data() {
    return {
      location: { lat: 10, lng: 10 },
      zoom: this.fetchZoomSize(),
    }
  },
  methods: {
    fetchMap() {
      let endPoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURI(
        this.address
      )}&key=${process.env.MAP_API_KEY}`
      axios({
        url: endPoint,
        method: 'GET',
      })
        .then(result => {
          this.location = result.data.results[0].geometry.location
        })
        .catch(err => {
          console.log(err) // eslint-disable-line no-console, no-undef
        })
    },
    fetchZoomSize() {
      return this.initialZoom ? parseInt(this.initialZoom) : DEFAULT_ZOOM_SIZE
    },
  },
}
