// 参考：https://so-zou.jp/web-app/tech/programming/javascript/event/handler/onscroll.htm

export function DelayLoadElement(
  parentEl,
  targetEl,
  padding_bottom /*下からスクロールする場合の要素のサイズ調整に使用（適度な値で良い）*/,
  callback
) {
  const CheckVisibility = function() {
    // 現在のスクロール量（IE、Firefox、Opera || Chrome、Safari）
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    // レンダリング領域の y サイズ
    const clientHeight =
      document.documentElement.clientHeight || document.body.clientHeight

    // 画面下端 y 座標
    const clientBottom = scrollTop + clientHeight
    // 要素の上端 y 座標
    const elTop = targetEl.offsetTop

    // 画面上橋 y 座標
    const clientTop = scrollTop
    // 要素の下端 y 座標
    const elBottom = targetEl.offsetTop + targetEl.offsetHeight + padding_bottom

    // 要素が見えているとき
    if (elTop < clientBottom && elBottom > clientTop) {
      // スクロール毎に呼ばれないようイベントハンドラ削除
      if (parentEl.removeEventListener) {
        parentEl.removeEventListener('scroll', CheckVisibility, false)
      } else if (parentEl.detachEvent) {
        parentEl.detachEvent('onscroll', CheckVisibility)
      }
      // コールバック処理
      callback(targetEl)
    }
  }

  // scroll 時のイベントハンドラに CheckVisibility を指定
  // Internet Explorer 9 より前をサポートしないならば、addEventListener だけで十分
  if (parentEl.addEventListener) {
    parentEl.addEventListener('scroll', CheckVisibility, false)
  } else if (parentEl.attachEvent) {
    parentEl.attachEvent('onscroll', CheckVisibility)
  } else {
    callback(targetEl)
  }
}
