document.addEventListener('DOMContentLoaded', () => {
  const header = document.getElementById('js-header')
  const stickyClass = 'is-sticky-header'
  let timer = null

  function setAttr() {
    if (window.scrollY > header.clientHeight) {
      document.body.classList.add(stickyClass)
    } else {
      document.body.classList.remove(stickyClass)
    }
  }

  function onScroll() {
    clearTimeout(timer)
    timer = setTimeout(() => setAttr(), 10)
  }

  if (header != null) {
    window.addEventListener('scroll', onScroll)
  }
})
