import $ from 'jquery'
import 'slick-carousel'

$(() => {
  $('#js-time-table').slick({
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    nextArrow:
      '<button type="button" class="slick-next">次の週 <i class="icon-chevron-right"></i></button>',
    prevArrow:
      '<button type="button" class="slick-prev"><i class="icon-chevron-left"></i> 前の週</button>',
  })
})
