document.addEventListener('DOMContentLoaded', () =>
  Array.from(document.querySelectorAll('.js-la-click')).forEach(dom =>
    dom.addEventListener('click', function(e) {
      gtag('event', 'click', {
        event_label: dom.getAttribute('data-label') || 'UNKNOWN',
        event_element: dom.getAttribute('data-id') || '',
        event_value: dom.getAttribute('data-action') || 'UNKNOWN',
        custom1: dom.getAttribute('data-custom1') || '',
        custom2: dom.getAttribute('data-custom2') || '',
        custom3: dom.getAttribute('data-custom3') || ''
      })
    })
  )
)

document.addEventListener('DOMContentLoaded', () =>
  Array.from(document.querySelectorAll('.js-la-click-next')).forEach(dom =>
    dom.addEventListener('click', function(e) {
      e.preventDefault()
      gtag('event', 'click', {
        event_label: dom.getAttribute('data-label') || 'UNKNOWN',
        event_element: dom.getAttribute('data-id') || '',
        event_value: dom.getAttribute('data-action') || 'UNKNOWN',
        custom1: dom.getAttribute('data-custom1') || '',
        custom2: dom.getAttribute('data-custom2') || '',
        custom3: dom.getAttribute('data-custom3') || ''
      })
      const nextUrl = dom.getAttribute('href')
      if (nextUrl) {
        location.href = nextUrl
      }
    })
  )
)
