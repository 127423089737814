import Datepicker from 'vuejs-datepicker'
import { ja } from 'vuejs-datepicker/dist/locale'
import moment from 'moment'
import axios from 'axios'

export default {
  name: 'DatepickerConsultation',
  components: {
    Datepicker,
  },
  props: ['attributeName', 'attributeId', 'initialText', 'hasError', 'isFront'],
  data() {
    return {
      content: this.initialText,
      selectabledDays: [],
      disabledDates: {
        to: moment()
          .subtract(1, 'days')
          .toDate(),
      },
      requiredAttr: true,
      format: 'yyyy-MM-dd',
      ja: ja,
    }
  },
  created() {
    if (this.isFront) {
      this.setDays()
    }
  },
  computed: {
    formatSelectedDate() {
      return moment(this.content).format('YYYY-MM-DD')
    },
    selectablePeriods() {
      if (this.content === '') return false

      const day = this.selectabledDays.find(
        day => day.date === this.formatSelectedDate
      )
      return day.selectable_periods
    },
    isChanged() {
      if (this.content === '') return false

      const beforeChangedDate = moment(this.$refs.programaticOpen.value).format(
        'YYYY-MM-DD'
      )
      return beforeChangedDate === this.formatSelectedDate
    },
  },
  methods: {
    opened() {
      this.requiredAttr = false
    },
    closed() {
      this.requiredAttr = true
      this.$eventHub.$emit('sendSelectOptions', this.selectablePeriods)
      if (!this.isChanged) {
        this.$eventHub.$emit('changeDate')
      }
    },
    openPicker() {
      this.$refs.programaticOpen.showCalendar()
    },
    setDays() {
      axios({
        url: '/sumai/calendars',
        method: 'GET',
      })
        .then(result => {
          const allDays = result.data.days
          const disabledDays = allDays
            .filter(day => !day.selectable_periods.length)
            .map(day => moment(day.date).toDate())

          this.selectabledDays = allDays.filter(
            day => day.selectable_periods.length
          )
          this.$eventHub.$emit('sendSelectOptions', this.selectablePeriods)
          this.disabledDates = {
            from: moment
              .parseZone(allDays.pop().date)
              .add(1, 'days')
              .toDate(),
            to: moment()
              .subtract(1, 'days')
              .toDate(),
            dates: disabledDays,
          }
        })
        .catch(err => {
          console.log(err) // eslint-disable-line no-console, no-undef
        })
    },
  },
}
