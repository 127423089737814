export default {
  name: 'StringCounter',
  props: [
    'attributeName',
    'attributeId',
    'initialText',
    'type',
    'rows',
    'required',
    'hasError',
    'maxlength',
    'minlength',
    'placeholder',
    'isCountDown',
    'isTextfield',
  ],
  data() {
    return {
      content: this.initialText || '',
    }
  },
  computed: {
    stringLength() {
      return this.isCountDown
        ? this.maxlength - this.content.length
        : this.content.length
    },
    counterText() {
      return this.isCountDown
        ? `あと${this.stringLength}文字まで`
        : `${this.stringLength}文字`
    },
    isFull() {
      return this.stringLength == 0
    },
  },
}
