// JavaScript
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue/dist/vue.esm'
import * as VueGoogleMaps from 'vue2-google-maps'
import '../javascripts/sp_nav'
import '../javascripts/sticky_header'
import '../javascripts/sticky_footer'
import '../javascripts/accordion'
import '../javascripts/disabled_controller'
import '../javascripts/chart'
import '../javascripts/slider'
import '../javascripts/lazysizes'
import '../javascripts/time-table'
import '../javascripts/init-event-tracking'
import EmailDomain from '../javascripts/components/EmailDomain'
import DatepickerEditor from '../javascripts/components/DatepickerEditor'
import DynamicSelect from '../javascripts/components/DynamicSelect'
import MapView from '../javascripts/components/MapView'
import CountDownTimer from '../javascripts/components/CountDownTimer'
import StringCounter from '../javascripts/components/StringCounter'
import EventHub from '../javascripts/utilities/EventHub'
import { DelayLoadElement } from './delay_load_element'

document.addEventListener('DOMContentLoaded', () => {
  const countDownTimerNode = document.getElementById('js-count-down-timer')
  if (countDownTimerNode != null) {
    new Vue({
      el: countDownTimerNode,
      components: {
        CountDownTimer,
      },
    })
  }

  const datepickerEditorNode = document.getElementById('js-form')
  if (datepickerEditorNode != null) {
    Vue.use(EventHub)
    new Vue({
      el: datepickerEditorNode,
      components: {
        DatepickerEditor,
        DynamicSelect,
        EmailDomain,
        StringCounter,
      },
    })
  }

  const mapViewNode = document.getElementById('js-map-view')
  if (mapViewNode != null) {
    DelayLoadElement(window, mapViewNode, 350, function(elem) {
      Vue.use(VueGoogleMaps, {
        load: {
          key: process.env.MAP_API_KEY,
          libraries: 'places',
        },
      })

      new Vue({
        el: elem,
        components: {
          MapView,
        },
      })
    })
  }
})

// StyleSheet
import 'reset-css'
import '../stylesheets/application'

// Image
require.context('images', true, /\.(png|jpg|jpeg|gif|svg)$/) // eslint-disable-line no-undef
