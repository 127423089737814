import $ from 'jquery'
import 'slick-carousel'

$(() => {
  const arrow = {
    prev:
      '<button type="button" class="slick-prev"><i class="icon-chevron-left"></button>',
    next:
      '<button type="button" class="slick-next"><i class="icon-chevron-right"></button>',
  }

  // 汎用的なスライダー
  $('.js-slick').each((index, element) => {
    const $parent = $(element)

    $parent.slick({
      centerMode: $parent.children().length > 2, // 2個以下だったら左寄せ
      infinite: true,
      nextArrow: arrow.next,
      prevArrow: arrow.prev,
      slidesToShow: 3,
      slidesToScroll: 3,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 769,
          settings: {
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  })

  // イメージギャラリーのスライダー
  $('#js-slick-gallery').slick({
    asNavFor: '#js-slick-gallery-nav',
    lazyLoad: 'progressive',
    nextArrow: arrow.next,
    prevArrow: arrow.prev,
  })

  // イメージギャラリーのスライダーのサムネイルナビゲーション
  $('#js-slick-gallery-nav').slick({
    asNavFor: '#js-slick-gallery',
    focusOnSelect: true,
    lazyLoad: 'progressive',
    nextArrow: arrow.next,
    prevArrow: arrow.prev,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
        },
      },
    ],
  })
})
