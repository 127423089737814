export default {
  name: 'DynamicSelect',
  props: ['attributeName', 'attributeId', 'initialValue', 'hasError'],
  data() {
    return {
      selectedValue: this.initialValue,
      requiredAttr: true,
      disabledAttr: true,
      selectOptions: [],
    }
  },
  created() {
    this.$eventHub.$on('sendSelectOptions', this.updateSelect)
    this.$eventHub.$on('changeDate', this.resetSelectedValue)
  },
  methods: {
    updateSelect(arr) {
      this.selectOptions = arr
      this.disabledAttr = !this.selectOptions.length
    },
    resetSelectedValue() {
      this.selectedValue = ''
    },
  },
}
