import moment from 'moment'

let countDownInterval = null

export default {
  name: 'CountDownTimer',
  props: ['receivingLastDate'],
  data() {
    return {
      now: moment(),
      diff: 0,
      isClosed: false,
    }
  },
  created() {
    countDownInterval = setInterval(() => {
      this.now = moment()
      this.diff = moment(this.receivingLastDate)
        .endOf('day')
        .diff(this.now)
    }, 1000)
  },
  computed: {
    days() {
      return Math.floor(moment.duration(this.diff).asDays())
    },
    hours() {
      return moment.duration(this.diff).hours()
    },
    minutes() {
      return moment.duration(this.diff).minutes()
    },
    seconds() {
      return moment.duration(this.diff).seconds()
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return '0' + value.toString()
      }
      return value.toString()
    },
  },
  watch: {
    now() {
      if (this.diff <= 0) {
        this.diff = 0
        this.isClosed = true
        clearInterval(countDownInterval)
      }
    },
  },
}
