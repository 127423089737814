import $ from 'jquery'

$(() => {
  function disabledToggle($target) {
    const $disabledTarget = $target
      .closest('form')
      .find('input:not(:hidden), select, textarea')
      .not($target)

    $disabledTarget.prop('disabled', $target.val() == 'false')
  }

  const $disabledController = $('.js-disabled-controller')
  $disabledController.on('change', event => {
    disabledToggle($(event.currentTarget))
  })
  disabledToggle($disabledController)
})
