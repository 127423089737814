export default {
  name: 'EmailDomain',
  props: ['attributeName', 'attributeId', 'initialText', 'hasError'],
  data() {
    return {
      content: this.initialText,
      domains: [
        '@gmail.com',
        '@yahoo.co.jp',
        '@docomo.ne.jp',
        '@i.softbank.jp',
        '@ezweb.ne.jp',
        '@icloud.com',
        '@hotmail.com',
        '@hotmail.co.jp',
      ],
      isOpened: false,
    }
  },
  methods: {
    focus(e) {
      if (window.matchMedia('(max-width: 767px)').matches) {
        window.scrollTo({
          top: e.currentTarget.getBoundingClientRect().top + window.pageYOffset,
          behavior: 'smooth',
        })
      }
      this.isOpened = true
    },
    blur() {
      setTimeout(() => {
        this.isOpened = false
      }, 200)
    },
    selectDomain(e) {
      const currentVal = this.content.split('@')
      const domain = e.currentTarget.textContent
      this.content = `${currentVal[0]}${domain}`
    },
  },
}
