import 'lazysizes'

window.lazySizesConfig = window.lazySizesConfig || {}

document.addEventListener('lazybeforeunveil', e => {
  const bg = e.target.getAttribute('data-bg')
  if (bg) {
    e.target.style.backgroundImage = 'url(' + bg + ')'
  }

  const script = e.target.getAttribute('data-script')
  if (script) {
    const elem = document.createElement('script')
    elem.src = script
    document.body.appendChild(elem)
  }
})
