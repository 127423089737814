document.addEventListener('DOMContentLoaded', () => {
  const spNav = document.getElementById('js-sp-nav')
  const spBtn = document.getElementById('js-sp-btn')
  const openedClass = 'is-sp-nav-opened'

  function setNavHeight() {
    const navHeight = spNav.clientHeight
    spNav.style.height = ''
    if (navHeight > window.screen.availHeight) {
      spNav.style.height = `${window.screen.availHeight}px`
    }
  }

  const scrollNode = document.scrollingElement || document.documentElement
  let scrollPos = 0
  function setPosition() {
    if (document.body.classList.contains(openedClass)) {
      document.body.style.top = ''
      document.body.classList.remove(openedClass)
      scrollNode.scrollTop = scrollPos
    } else {
      scrollPos = scrollNode.scrollTop
      document.body.style.top = `-${scrollPos}px`
      document.body.classList.add(openedClass)
    }
  }

  let timer = null
  function onResize() {
    setNavHeight()
    if (
      window.matchMedia('(min-width: 768px)').matches &&
      document.body.classList.contains(openedClass)
    ) {
      clearTimeout(timer)
      timer = setTimeout(() => {
        document.body.style.top = ''
        document.body.classList.remove(openedClass)
      }, 10)
    }
  }

  if (spBtn != null) {
    spBtn.addEventListener('click', () => {
      setNavHeight()
      setPosition()
    })
    window.addEventListener('resize', onResize)
  }
})
