import highcharts from 'highcharts'
import highchartsMore from 'highcharts-more'

highchartsMore(highcharts)

document.addEventListener('DOMContentLoaded', () => {
  const createChart = element => {
    const data = element.dataset
    const options = {
      fillColor: 'rgba(247, 130, 0, 0.2)',
      chart: {
        renderTo: element,
        polar: true,
        type: 'line',
        margin: [30, 20, 0, 20],
        height: 270,
        style: {
          fontWeight: 'bold',
        },
      },
      title: {
        text: '',
      },
      xAxis: {
        categories: [
          `説明のわかりやすさ<br><span style="color: #ff6123; font-size: 1.8rem;">${data.point1}</span>`,
          `面談時間<br><span style="color: #ff6123; font-size: 1.8rem;">${data.point2}</span>`,
          `全体的な<br>満足度<br><span style="color: #ff6123; font-size: 1.8rem;">${data.point3}</span>`,
        ],
        labels: {
          y: -20,
        },
        tickmarkPlacement: 'on',
        lineWidth: 0,
        gridLineWidth: 0.7,
      },
      yAxis: {
        labels: {
          enabled: false,
        },
        gridLineInterpolation: 'polygon',
        lineWidth: 0,
        min: 0,
        max: 5,
        tickAmount: 6,
        gridLineWidth: 0.7,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          marker: {
            enabled: false,
          },
          enableMouseTracking: false,
          type: 'area',
          color: '#ff6123',
          lineWidth: 0.7,
          pointPlacement: 'on',
          data: [
            parseInt(data.point1, 10),
            parseInt(data.point2, 10),
            parseInt(data.point3, 10),
          ],
        },
      ],
    }
    new highcharts.Chart(options)
  }
  ;[].forEach.call(document.getElementsByClassName('js-chart'), element => {
    createChart(element)
  })
})
