document.addEventListener('DOMContentLoaded', () => {
  const threshold = 980
  const stickyFooter = document.getElementById('js-sticky-footer')
  const stickyClass = 'is-sticky-footer'
  let timer = null

  function setAttr() {
    if (window.pageYOffset > threshold) {
      document.body.classList.add(stickyClass)
      document.body.style.paddingBottom = `${stickyFooter.clientHeight}px`
    } else {
      document.body.classList.remove(stickyClass)
      document.body.style.paddingBottom = ''
    }
  }

  function onScroll() {
    if (window.matchMedia('(max-width: 767px)').matches) {
      clearTimeout(timer)
      timer = setTimeout(() => setAttr(), 10)
    }
  }

  if (stickyFooter != null) {
    window.addEventListener('scroll', onScroll)
  }
})
